<template><div><h1 id="flashing-stock-firmware" tabindex="-1"><a class="header-anchor" href="#flashing-stock-firmware"><span>Flashing Stock Firmware</span></a></h1>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>If your device has hit AUE (Auto Update Expiration), you will not be able to revert to chromeOS.</p>
</div>
<ol>
<li>
<p>If you already have Linux installed, move on to step 5. Otherwise, make a <RouteLink to="/docs/installing/bootableusb.html">bootable Linux live USB</RouteLink>.</p>
</li>
<li>
<p>Connect to WiFi.</p>
</li>
<li>
<p>Open a terminal, then update and install <code v-pre>curl</code>.</p>
<ul>
<li>Use the distro's package manager to install it.</li>
</ul>
</li>
<li>
<p>Obtain your ROM backup.</p>
</li>
<li>
<p>Run <a href="https://mrchromebox.tech/#fwscript" target="_blank" rel="noopener noreferrer">MrChromebox's firmware utility script</a>.</p>
<ul>
<li>In case you forgot, type <code v-pre>cd; curl -LO mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code> and press Enter.</li>
</ul>
</li>
<li>
<p>Choose option 2: <code v-pre>Restore Stock ChromeOS Firmware</code>.</p>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>If, for whatever reason, lost your stock firmware backup, Use an additional drive to flash a <RouteLink to="/docs/reverting/making-recovery-usb.html">recovery image</RouteLink>.</p>
</div>
</li>
<li>
<p>Follow on-screen instructions</p>
</li>
</ol>
</div></template>


